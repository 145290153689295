import "./App.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Landing from "./pages/Landing";
import Minting from "./pages/Minting";
import Farm from "./pages/Farm";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/app">
          <Route path="minting" element={<Minting />} />
          <Route path="farm" element={<Farm />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
