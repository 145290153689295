import React from "react";

function LearnMore() {
  return (
    <>
      <div className="section-sm mb-20">
        <h2 className="section-title">Want to Learn More?</h2>
        <p className="section-subtitle pt-10 mx-10 md:mx-auto">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 15
        </p>
        <button className="section-btn">Read Whitepaper</button>
      </div>
    </>
  );
}

export default LearnMore;
