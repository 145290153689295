import React, { useState } from "react";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { Link } from "react-router-dom";
import { funkyNinja } from "../../assets/images/images";
import CountField from "./CountField";

function Mint() {
  return (
    <>
      <div className="bg-bgPrimaryDark py-6 px-2 md:p-12">
        <div className="bg-[#337A83] rounded-2xl md:max-w-6xl mx-auto md:flex md:items-center">
          <div className="md:w-1/2 bg-[#34A0AE] rounded-2xl p-6 ">
            <div className="bg-[#A8D4E5] rounded-2xl p-6">
              <div className="text-center font-enriqueta font-black text-[#622F00] text-5xl">
                Mint Funkies
              </div>
              <div className="text-center font-inika text-[#622F00] text-2xl">
                100 minted out of 1000
              </div>
              <div className="text-center font-inika text-[#622F00] text-3xl my-10">
                Select Quantity
              </div>
              <div className="my-10">
                <CountField />
                <div className="text-center font-inika text-[#622F00] text-lg">
                  Total Cost: 1 EGLD
                </div>
              </div>

              <Link to="/app/minting">
                <button className="connect-btn flex justify-center px-16 ">
                  Mint
                </button>
              </Link>
            </div>
          </div>

          <div className="md:w-1/2 flex justify-center py-16 md:py-auto">
            <img src={funkyNinja} alt="Funkies" />
          </div>
        </div>
      </div>
    </>
  );
}

export default Mint;
