import React, { useState } from "react";
import { funkyNinja } from "../../assets/images/images";
import CountField from "./CountField";
import { Link } from "react-router-dom";

function Burn() {
  return (
    <>
      <>
        <div className="w-full bg-[#34A0AE]  max-w-6xl mx-auto rounded-2xl p-6">
          <div className="bg-[#337A83] rounded-2xl p-6">
            <div className="text-center section-title text-5xl">
              Burn Funky Eggs
            </div>
            <div className="text-center font-inika text-[#DFFF1A] text-2xl mt-3 ">
              Burn and Get New Funkies
            </div>
            <div className="text-center font-inika text-[#DFFF1A] text-3xl my-8">
              Select Quantity
            </div>

            <div className="my-10">
              <CountField />
            </div>

            <Link to="/app/minting">
              <button className="connect-btn flex justify-center px-16">
                Burn
              </button>
            </Link>
          </div>
        </div>
      </>
    </>
  );
}

export default Burn;
