import React from "react";
import "../../assets/css/connect-btn.css";

function ConnectBtn() {
  return (
    <button className="connect-btn flex justify-center ">Connect Wallet</button>
  );
}

export default ConnectBtn;
