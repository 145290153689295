import React from "react";
import { funkyNinjaGallery } from "../../assets/images/images";

function Gallery(props) {
  const { title, count } = props;
  return (
    <>
      <div className="section">
        <div className="section-title">{title}</div>
        <div className="flex flex-wrap content-start justify-evenly gap-x-2 gap-y-8 my-6 md:justify-between md:gap-x-10 md:gap-y-16 md:my-20 md:mx-16">
          {count.map((image) => (
            <>
              <img
                src={funkyNinjaGallery}
                alt="Gallery-Img"
                className="md:w-max w-1/3 md:h-auto"
              />
            </>
          ))}
        </div>
      </div>
    </>
  );
}

export default Gallery;
